import React from "react";
import "./about.css";
import Footer from "../../Components/Footer/Footer";

const About = () => {
  return (
    <div className="rmnw__about-wrapper">
      <div className="rmnw__about-hero">
      <h1> About Us</h1>
        <div className="rmnw__about-hero-overlay">
        
        
        </div>
      </div>

      <div className="rmnw__about-owners">
        <h1>The Owners</h1>
        <p>
        The Railmakers has been in Everett, WA since 1972. In 1975 Mark Reeves graduated from a welding tech school in Kirkland and began to work there. In 2011 Mark & Pam took over ownership. The name then became Railmakers NW LLC. <br/><br/>

Mark has long held a combined passion for welding and boats as is shown in his 47 + years commitment to pride of workmanship for his clients in the Pacific Northwest and beyond. Mark would tell you his favorite part of his business is all the wonderful people he gets to meet or has know for near 5 decades. It's hard to miss him as he travels up and down I-5 in his signature blue Railmakers NW truck.<br/><br/>
</p>
<div className="rmnw__about-owners-image-container">
  <img src="/media/rmnw__about-owners-1.jpg">
  </img>
  <img src="/media/rmnw__about-owners-2.JPG">
  </img>
</div>
<p>
Mark and Pam also have an ongoing love for Harley Davidson Motorcycles, and have been H.O.G. Chaplains for 20+ years. They support the <a href="https://www.burnedchildrenrecovery.org/">Burned Children's Recovery Foundation</a> among other charities. They also "do" Santa & Mrs. Claus for military service members' family events, hospital events (pre-covid) and at the Sound Harley Dealership. They even do private homes and ask that donations be made to the Burned Children's Recovery Foundation. Mark Officiates weddings and memorial services.
        </p>
        <div className="rmnw__about-owners-image-bottom">
        <img  src="/media/rmnw__about-owners-3.jpg"></img>
        </div>
      </div>

      <div className="rmnw__about-crew">
        <h1>The Crew</h1>
        <p>
        We strive to bring family values to our business, and as such our team includes Mark's brother, Guy, who is the office manager. His son Tyler does fabrication and is an Electro Polish Technician. His youngest son Cody is the Web Developer.<br/><br/>

The lead Forman of the welding team is Rach. He has been an invaluable and loyal asset to the Railmakers for 30+ years. He brings his expertise to every project he works on. The rest of the welding team, Brice & Devon, have big shoes to fill. Rach won't hesitate to guide them when needed and they have proven they are up to the task.
        </p>
        <div className="rmnw__about-crew-images">
          <img className="rmnw__about-crew-collage" src="/media/rmnw__about-crew-collage.png" alt="a collage of our crew members welding"/>
        </div>
        <div className="rmnw__about-crew-images-mobile">
          <img className="rmnw__about-crew-mobile-1" src="/media/rmnw__about-crew-welding-1.JPG" alt="A team member welding"/>
          <img className="rmnw__about-crew-mobile-2" src="/media/rmnw__about-crew-welding-2.jpg" alt="A team member welding"/>
          <img className="rmnw__about-crew-mobile-3" src="/media/rmnw__about-crew-welding-3.jpg" alt="A team member welding"/>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default About;
