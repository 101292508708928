import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className="rmnw__navbar-container">
      <div className="rmnw__navbar-home">
        <Link to="/" onClick={scrollToTop}>Home</Link>
      </div>
      <div className="rmnw__navbar-services">
        <Link to="/services" onClick={scrollToTop}>Services</Link>
      </div>
      <div className="rmnw__navbar-about">
        <Link to="/about" onClick={scrollToTop}>About Us</Link>
      </div>
      <div className="rmnw__navbar-contact">
        <Link to="/contact" onClick={scrollToTop}>Contact Us</Link>
      </div>
      {toggleMenu && (
        <div className="rmnw__navbar-dropdown">
          <p>
            <Link className="dropdown-item" to="/" onClick={scrollToTop}>
              Home
            </Link>
          </p>
          <p>
            <Link className="dropdown-item" to="/services" onClick={scrollToTop}>
              Services
            </Link>
          </p>
          <p>
            <Link className="dropdown-item" to="/about" onClick={scrollToTop}>
              About Us
            </Link>
          </p>
          <p>
            <Link className="dropdown-item" to="/contact" onClick={scrollToTop}>
              Contact Us
            </Link>
          </p>
        </div>
      )}
      <div className="rmnw__navbar-image">
        <img src="/media/rmnwLogo.png" alt="logo" />
      </div>
      <div className="rmnw__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
      </div>
    </div>
  );
};

export default Navbar;
